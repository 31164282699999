export const Work = [
    {
        title: "Sr. Software Engineer",
        employer: "WellSky",
        dates: "Oct 2019-Present",
        location: "Remote",
        descriptions: ["Lead engineer tasked with resiliency, performance, and scalability research for the core application",
            "Worked with teams internally and externally to identify and improve processes within the application requiring optimization",
            "Created in-depth documentation for general backend development best practices and performance standards",
            "Integrated and maintained New Relic service reporting for client servers ",
            "Implemented various custom New Relic integrations using Javascript, Java, GraphQL, and bash",
            "Instrumental in creating server monitoring alerts and dashboards. Tools set up were used to monitor for critical failures related to cpu utilization, memory usage, network latency, general application performance, etc."
        ]
    },
    {
        title: "Backend Developer",
        employer: "HCS (Acquired by WellSky)",
        dates: "Jul 2017-Oct 2019",
        location: "Wall, NJ",
        descriptions: ["Implemented code enhancements and bug fixes for the application’s Billing/Revenue module of application",
            "Practiced agile software development",
            "Gained comprehensive knowledge of long term care and skilled nursing business operations",
            "Provided clients with support on technical aspects of core application"
        ]
    }
];

export const Education = [{
    school: "Rowan University",
    date: "May 2017",
    location: "Glassboro, NJ",
    degree: "Bachelor of Science in Computer Science",
    description: "Specialization: Artificial Intelligence",
    coursework: [
        "Java Development",
        "Software Engineering",
        "Artificial Intelligence",
        "Machine Learning",
        "Data Structures",
        "Algorithms",
        "Operating Systems",
        "Data Communications & Networking"
    ]
}];



export const Skills = [{
    type: "language",
    value: "RPG",
    color: "teal"
},
{
    type: "language",
    value: "CL",
    color: "teal"
},
{
    type: "language",
    value: "Java",
    color: "teal"
},
{
    type: "language",
    value: "JavaScript",
    color: "teal"
},
{
    type: "language",
    value: "Kotlin",
    color: "teal"
},
{
    type: "language",
    value: "SQL",
    color: "teal"
},
{
    type: "language",
    value: "NRQL",
    color: "teal"
},
{
    type: "language",
    value: "GraphQL",
    color: "teal"
},
{
    type: "language",
    value: "Python",
    color: "teal"
},
{
    type: "framework",
    value: "React",
    color: "red"
},
{
    type: "framework",
    value: "ExtJS",
    color: "red"
},
{
    type: "framework",
    value: "Node",
    color: "red"
},
{
    type: "technology",
    value: "IntelliJ",
    color: "purple"
},
{
    type: "technology",
    value: "Eclipse",
    color: "purple"
},
{
    type: "technology",
    value: "Rational",
    color: "purple"
},
{
    type: "technology",
    value: "VSCode",
    color: "purple"
},
{
    type: "technology",
    value: "Bash",
    color: "purple"
},
{
    type: "technology",
    value: "Git/GitHub",
    color: "purple"
},
{
    type: "technology",
    value: "Jira",
    color: "purple"
},
{
    type: "OS",
    value: "Windows",
    color: "blue"
},
{
    type: "OS",
    value: "IBM i/Websphere",
    color: "blue"
},
{
    type: "OS",
    value: "Linux",
    color: "blue"
},
{
    type: "OS",
    value: "Unix",
    color: "blue"
}];
